
























































































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { FormProduto, FiltroDeBuscaDeProdutos } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import EdicaoDeRegrasFiscaisDoProduto from '@/components/produto/EdicaoDeRegrasFiscaisDoProduto.vue'
import EditarMultiplosProdutosUseCase, { CamposAceitos } from '@/usecases/produto/EditarMultiplosProdutosUseCase'
import MenuDeFiltrosDeProduto from '@/components/produto/MenuDeFiltrosDeProduto.vue'
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import SeletorDeCest from '@/components/produto/SeletorDeCest.vue'
import SeletorDeCategoria from '@/views/application/produtos/EdicaoDeProduto/SeletorDeCategoria.vue'
 
@Component({
	components: {
		EdicaoGenerica,
		EdicaoDeRegrasFiscaisDoProduto,
		MenuDeFiltrosDeProduto,
		SeletorDeTag,
		SeletorDeCest,
		SeletorDeCategoria,
		Confirmacao,
	},
})
export default class TelaDeEdicaoMultiplaDeProdutos extends Vue {
	@Prop({ type: [Array, Object], default: () => [] }) selecionados!: string[] | FiltroDeBuscaDeProdutos
	@Ref() confirmacaoDeEdicaoEmMassa!: Confirmacao

	busca = ''
	filtroAplicado: FiltroDeBuscaDeProdutos = {}
	campos: CamposAceitos[] = []
	valores: Partial<FormProduto> = {
		regrasFiscais: [],
		tags: [],
		cest: null,
		categoria: null,
	}
	enviando = false
	erro: null | Error = null
	msgConfirmacao = 'Tem certeza que deseja alterar todos os produtos da conta?'
	infoAlteracao = true
	edicaoMassiva = true

	async salvar() {
		try {
			this.enviando = true
			AlertModule.setSuccess('Itens em processamento, isto pode levar alguns minutos')
			await EditarMultiplosProdutosUseCase({
				campos: this.campos,
				filtro: this.filtroAplicado,
				valores: this.valores,
				edicaoMassiva: this.edicaoMassiva,
			})
			AlertModule.setSuccess('Atualização concluída com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.enviando = false
		}
	}

	filtrar(filtros: FiltroDeBuscaDeProdutos = {}) {
		this.filtroAplicado = {
			...filtros,
			nome: this.busca || undefined,
		}
		if(!(Object.values(this.filtroAplicado).filter(i => i != undefined)).length){
			this.msgConfirmacao = 'Tem certeza que deseja alterar todos os produtos da conta?'
			this.infoAlteracao = true
		} else {
			this.msgConfirmacao = 'Tem certeza que deseja alterar todo os produtos selecionados no filtro?'
			this.infoAlteracao = false
		}
	}
}
